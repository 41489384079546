<template>
  <div class="inspired text-blue">
    <!-- <b-row>
      <b-col class="col-10 position-relative">
        <img src="/img/townhouse.jpg" alt="Townhouse" class="img-fluid" />
        <div class="town-text rotate">
          <div class="acumin-pro-wide-medium">TOWNHOMES</div>
          <div class="line"></div>
        </div>
      </b-col>
    </b-row> -->
    <!-- <div class="container">
      <b-row class="pt-5">
        <b-col cols="12" md="6">
          <p class="large-text freight-big-pro-book">
            INSPIRED DESIGNS <br />
            AND FEATURES
          </p>
        </b-col>
        <b-col cols="12" md="6" class="d-flex align-items-end">
          <div>
            <object
              data="/img/BLUE_SEAL.svg"
              type="image/svg+xml"
              class="seal"
            ></object>
            <p class="acumin-pro-wide-light pt-3 sub-text">
              <span class="acumin-pro-wide-medium">WELCOME HOME.</span> <br />
              A home designed for life, in a community that inspires. A place
              for your family to flourish and thrive in a vibrant neighbourhood.
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row class="pt-5 pb-md-5">
        <b-col md="12">
          <img
            src="/img/elevation-01.jpg"
            alt="Elevation 1"
            class="img-fluid w-100 max-w-650 d-block"
          />
          <div class="dk-version text-right-heights max-w-300 text-center text-md-left pt-5">
            <p class="freight-big-pro-book sub-md-text">
              TOWNHOMES, 40' AND 50' SINGLES
            </p>
            <p class="acumin-pro-wide-medium sub-text">
              SET YOUR SIGHTS ON UNCOMPROMISING <br /> STYLE AND COMFORT.
            </p>
          </div>
          <div class="text-heights max-w-350 text-center text-md-right pt-5">
            <p class="sub-md-text freight-big-pro-book-italic lg-screen-text">
              Towns <span class="freight-big-pro-book xs-text">FROM THE LOW</span>  $700’s,   
            </p>
            <p class="sub-md-text freight-big-pro-book-italic lg-screen-text"> 
              40' Detached <span class="freight-big-pro-book xs-text">FROM THE LOW</span> $900’s, 
            </p>
            <p class="sub-md-text freight-big-pro-book-italic lg-screen-text">
              50'
              Detached <span class="freight-big-pro-book xs-text">FROM THE LOW</span> $1M’s
            </p>
          </div>
          <div class="overlap-img">
            <img
              src="/img/elevation-02.jpg"
              alt="Elevation 1"
              class="img-fluid w-100 max-w-500 d-block"
            />
          </div>
          <div class="mb-version text-heights max-w-300 text-center text-md-left pt-5">
            <p class="freight-big-pro-book sub-md-text">
              TOWNHOMES, 40' AND 50' SINGLES
            </p>
            <p class="acumin-pro-wide-medium sub-text">
              SET YOUR SIGHTS ON UNCOMPROMISING <br /> STYLE AND COMFORT.
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row class="pt-md-5">
        <b-col cols="12" md="8" class="d-flex justify-content-center">
          <img
            src="/img/elevation-03.jpg"
            alt="Elevation 1"
            class="img-fluid w-100 max-w-500 d-block"
          />
        </b-col>
        <b-col cols="12" md="4" class="d-flex align-items-center">
          <div class="pt-5">
            <p class="text-white lg-text freight-big-pro-book">SOUTH</p>
            <p class="text-white lg-text freight-big-pro-book-italic">BAY</p>
            <p class="text-white md-text freight-big-pro-book">
              AT RIVERS EDGE
            </p>
          </div>
        </b-col>
      </b-row>
    </div> -->
    <b-row class="pt-5">
      <b-col cols="10" md="col-8">
        <img
          src="/img/elevation-04.jpg"
          alt="Townhouse"
          class="img-fluid"
        />
      </b-col>
    </b-row>
    <b-container>
      <div
        class="text-overlay d-flex align-items-center justify-content-between"
      >
        <object
          data="/img/BLUE_SEAL.svg"
          type="image/svg+xml"
          class="seal-md"
        ></object>
        <p class="freight-big-pro-book large-text">INTERIORS</p>
      </div>

      <b-row class="d-flex justify-content-center">
        <b-col cols="12" md="6" class="sub-text">
          <p class="acumin-pro-wide-light">
            Explore interior spaces designed to meet all your needs. Each
            residence is thoughtfully planned with family-friendly open concept
            layouts, large windows to let in natural light, dining spaces for
            entertaining and ensuites for peaceful relaxation.
          </p>
          <p class="acumin-pro-wide-medium pt-md-5">
            GRACIOUSLY DESIGNED. <br />
            DETAILED TO PERFECTION.
          </p>
        </b-col>
        <b-col cols="12" md="6">
          <img
            src="/img/elevation-05.jpg"
            alt="Elevation 1"
            class="img-fluid w-100 max-w-500 d-block"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {
    }, 500);
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.inspired {
  padding: 100px 0px 100px 0px;
  .seal-md {
    width: 140px;
    @media screen and (max-width: 500px) {
      width: 100px;
    }
  }
  .seal {
    max-width: 140px;
  }
  .line {
    border-bottom: 1px solid #0b2340;
    position: relative;
    right: -130px;
    bottom: 8px;
    width: 100px;
    @media screen and (min-width: 2500px) {
      right: -250px;
      bottom: 14px;
      width: 180px;
    }
    @media screen and (min-width: 1500px) {
      right: -180px;
      bottom: 12px;
      width: 160px;
    }
    @media screen and (max-width: 500px) {
      right: -105px;
      bottom: 7px;
      width: 40px;
    }
  }
  .lg-text {
    font-size: 6rem;
    line-height: 3.5rem;
    @media screen and (min-width: 1500px) {
    font-size: 5vw;
    line-height: 0.6125;
  }
  @media screen and (min-width: 2500px) {
    font-size: 7rem;
    line-height: 4.5rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 5.5rem;
    line-height: 3rem;
  }
  }
  .xs-text {
        font-size: 12px;
      }

      .lg-screen-text {
        @media screen and (min-width: 1500px) {
          font-size: 1.3vw;
          line-height: 0.4125;
        }
        @media screen and (min-width: 2500px) {
          font-size: 2rem;
          line-height: 1rem;
          padding-bottom: 20px;
        }
      }
  .md-text {
    font-size: 1.5rem;
    line-height: 23px;
    @media screen and (min-width: 1500px) {
    font-size: 1.66667vw;
    line-height: 0.6125;
  }
  @media screen and (min-width: 2500px) {
    font-size: 2rem;
    line-height: 1rem;
  }
  }
  .town-text {
    position: absolute;
    right: -65px;
    bottom: 50px;
    @media screen and (min-width: 2500px) {
      font-size: 2rem;
      right: -135px;
      bottom: 100px;
    }
    @media screen and (min-width: 1500px) {
      font-size: 1.4rem;
      right: -95px;
      bottom: 70px;
    }
    @media screen and (max-width: 500px) {
      font-size: 0.8rem;
      right: -50px;
      bottom: 38px;
    }
  }
  .rotate {
    transform: rotate(-90deg);
    /* Legacy vendor prefixes that you probably don't need... */
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }
  .overlap-img {
    position: absolute;
    bottom: -50px;
    right: 50px;
    @media screen and (max-width: 991px) {
      position: relative;
      bottom: unset;
      right: unset;
    }
  }
  .text-heights {
    margin-left: 200px;
    @media screen and (max-width: 1200px) {
      margin-left: 50px;
    }
    @media screen and (max-width: 500px) {
      margin-left: 0px;
      margin: auto;
      max-width: fit-content;
    }
  }
  .dk-version {
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
  .mb-version {
    display: none;
    @media screen and (max-width: 1000px) {
      display: block;
    }
  }
  .text-right-heights {
    position: absolute;
    right: 150px;
    top: 0;
    @media screen and (max-width: 1200px) {
      right: -30px;
        }
  }
  .text-overlay {
    position: relative;
    top: -60px;
    padding: 0px 20px 0px 50px;
    @media screen and (max-width: 500px) {
      top: -40px;
    }
    }
}
</style>
